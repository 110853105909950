import * as React from "react"

import Layout from "../components/layout"
import { Header } from "../components/header"
import { ImageWithText } from "../components/imageWithText"
import { Footer } from "../components/footer"

const Offer = () => {
  const sections = [
    {
      header: "Manipulacje wisceralne",
      text: "Technika ta została stworzona przez francuskiego osteopatę Jean Pierre’a Barrala, oparta jest na przywracaniu harmonii oraz usuwaniu napięć w obrębie tkanki łącznej. Przywraca prawidłowe położenie narządów wewnętrznych, co skutkuje poprawą jakości i wydolności ich pracy. Terapia oparta jest na testowaniu zaburzeń w obrębie całego ciała, jak i miejscowym badaniu pracy poszczególnych narządow. Nieprawidłowa praca organów może powodować szereg dolegliwości w układzie ruchu,zatem terapia ta skierowana jest do osób borykających się z przewlekłym bólem w obrębie kręgosłupa lub kończyn, bólami głowy, problemami ze strony układu trawiennego,układu moczowego a przede wszystkim systemu nerwowego.",
      img: "images/offer/2.jpg",
    },
    {
      header: "Holistic Scanning",
      text: "Terapia dzięki której przywrócisz harmonię i równowage w swoim organizmie. Jest to metoda pracy na układzie nerwowym, czyli centrum dowodzenia naszego ciała. Pozwala usunąć dolegliwości zarówno na poziomie fizycznym oraz emocjonalnym, dotyka objawów psychosomatycznych. Traumy spowodowane przykrymi zdarzeniami z życia mogą aktywować się po kilku, a nawet kilkunastu latach. Dzięki technice Holistic Scanning możemy skutecznie usunąć odpowiedź układu nerwowego na przebyte urazy, przywrócić balans w napięciu mięśniowym zrównoważyć pracę narządów wewnętrznych.",
      img: "images/offer/3.jpg",
    },
    {
      header: "Terapia Manualna",
      text: "Praca terapią manualną polega na przywróceniu prawidłowego ustawienia stawów kończyn i kręgosłupa, co za tym idzie zmniejszenie napięcia mięśniowego oraz usunięcie objawów neurologicznych takich jak: drętwienie, mrowienie, pieczenie czy ograniczenie ruchomości. Technika ta jest całkowicie bezbolesna.",
      img: "images/offer/4.jpg",
    },
    {
      header: "Modelowanie twarzy",
      text: "Naturalne odmłodzenie bez użycia igieł i kwasów, głęboki masaż przywracający elastyczność tkanek a co za tym idzie zdrowy i młody wygląd. Regularnie wykonywany spłyca zmarszczki i redukuje blizny. Dodatkowo proces ten wspierany jest taśmami które działają jak drenaż likwidując obrzęki i opuchliznę",
      img: "images/offer/5.jpg",
    },
    {
      header: "Masaż relaksacyjny",
      text: "Zabieg idealny dla osób żyjących w stresie, prowadzących aktywny tryb życia. Masaż obejmuje całe ciało, pozwala się zrelaksować i odprężyć. Pozwala wyciszyć ciało i umysł, przywrócić równowagę w ciele.",
      img: "images/offer/6.jpg",
    },
  ]

  return (
    <Layout>
      <Header pageName="offer" text="Oferta" />
      {sections.map(({ header, text, img }) => (
        <ImageWithText header={header} text={text} imgSrc={img} shadow={true} />
      ))}
      <div className="discorders--containers--buttons">
        <a className="discorders--containers--btn font-size-24" href="">
          Kontakt
        </a>
        <a className="discorders--containers--btn font-size-24" href="">
          Cennik
        </a>
      </div>
      <Footer />
    </Layout>
  )
}

export default Offer
